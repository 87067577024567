import { type Path, type PathValue, resolveValue } from '@intlify/core-base';

export default defineI18nConfig(() => ({
  // WHEN CHANGING I18N CONFIGURATION, ALSO CHANGE IT IN PRESET IN entry-prefix.tmpl fOR WIDGET!
  // CORRECT VALUES FOR ENTRY_PREFIX CAN BE FOUND BY INSPECTING window.__NUXT__.config WHEN OPENING https://widget-whitelabel.infomax.dev

  legacy: false,
  messageResolver: (obj: unknown, path: Path): PathValue => {
    const whlConfig = useWhlInstanceConfig();
    const instanceId = whlConfig.value.instanceId;

    const primaryResult = (obj as Record<string, string>)[
      instanceId + '.' + path
    ];
    if (primaryResult) {
      return primaryResult as PathValue;
    }
    return resolveValue(obj, path);
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      kilometer: {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        notation: 'standard',
      },
      meter: {
        style: 'unit',
        unit: 'meter',
        unitDisplay: 'short',
      },
    },
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      kilometer: {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        notation: 'standard',
      },
      meter: {
        style: 'unit',
        unit: 'meter',
        unitDisplay: 'short',
      },
    },
    nl: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      kilometer: {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        notation: 'standard',
      },
      meter: {
        style: 'unit',
        unit: 'meter',
        unitDisplay: 'short',
      },
    },
  },
  datetimeFormats: {
    en: {
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      medium: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
      },
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
      weekday: {
        weekday: 'long',
      },
    },
    de: {
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      medium: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
      },
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
      weekday: {
        weekday: 'long',
      },
    },
    nl: {
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      medium: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
      },
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
      weekday: {
        weekday: 'long',
      },
    },
  },
}));
